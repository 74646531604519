.wrapper {
    overflow: hidden;
}

.posts {
    padding: 1rem 4rem 0 0;
    height: 100%;
    overflow-y: scroll;
}

.load_more {
    color: var(--color-primary);
    text-align: center;
    font-weight: bold;
    padding: 0.5rem 0 1rem 0;
    cursor: pointer;
}
