.wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: var(--header-height) 1fr;
  grid-template-columns: 1fr 18rem;
  grid-template-areas:
    "header header"
    "articleList sideMenu";
}

.header {
  grid-area: header;
}

.articleList {
  grid-area: articleList;
}

.sideMenu {
  grid-area: sideMenu;
}
