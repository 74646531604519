.post {
    position: relative;
    min-height: var(--article-size);
    margin-bottom: 0.5rem;
    background-color: var(--color-post);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: var(--shadow-el-1);
}

.bar {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: var(--article-size) var(--article-size) 1fr;
    grid-template-areas: "score thumbnail info";
}

.stickied {
    color: var(--color-font-secondary);
    margin-right: 0.5rem;
}

.score {
    grid-area: score;
    text-align: center;
    line-height: var(--article-size);
    color: var(--color-font-secondary);
    font-weight: bold;
    font-size: 1rem;
    opacity: 0.5;
}

.thumbnail {
    grid-area: thumbnail;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* border-radius: 0.25rem; */
    /* margin: 0.5rem 0; */
    /* background-color: black; */
}
.thumbnail_image {
    background-color: black;
}
.thumbnail_subreddit {
    opacity: 0.5;
}
.thumbnail_icon {
    grid-area: thumbnail;
    color: var(--color-font-tertiary);
    text-align: center;
    line-height: var(--article-size);
    opacity: 0.5;
}
.thumbnail_icon::before {
    font-size: 3rem;
}


.body {
    grid-area: info;
    padding-left: 1rem;

    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-template-rows: 1fr 1.5rem 1.5rem;
    grid-template-areas:
        "title title"
        "icon submission"
        "icon comments";
}
.body.body_empty {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.5rem 1.5rem;
    grid-template-areas:
        "title"
        "submission"
        "comments";
}

.title {
    grid-area: title;
    color: var(--color-font-main);
    font-weight: bold;
    padding-top: 0.5rem;
    padding-right: 1rem;
    font-size: 1rem;
}

.icon {
    grid-area: icon;
    color: var(--color-primary);
    border-radius: 1rem;
    line-height: 3rem;
    cursor: pointer;
}
.icon::before {
    font-size: 1.5rem;
}

.submission {
    grid-area: submission;
    font-size: 0.75rem;
    color: var(--color-font-tertiary);
    padding: 0.25rem 0;
}

.comments {
    grid-area: comments;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
}

.load_comments {
    color: var(--color-primary);
    font-weight: bold;
    text-align: center;
    font-size: 0.8rem;
    cursor: pointer;
}

.comments_wrapper {
    max-height: 30rem;
    overflow: scroll;
    padding: 0.25rem 0 0.5rem 0;
    margin: 0 1rem 0 var(--article-size);
}

.comments_wrapper.hide {
    display: none;
}

.content_wrapper {
    text-align: center;
    padding: 1rem 1rem 1rem var(--article-size);
}

.content_wrapper.hide {
    display: none;
}

.subreddit_link {
    cursor: pointer;
    font-weight: bold;
    color: var(--color-primary);
}
