.video {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 70vh;
    cursor: pointer;
}

.controls {
    display: grid;
    grid-template-columns: 3rem 1fr 3rem 10rem;
    grid-template-rows: 3rem;
    grid-template-areas: "playpause_icon progress_bar volume_icon volume_bar";
}

.control {
    color: var(--color-font-main);
    line-height: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 1rem;
}
.control:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.playpause_icon {
    grid-area: playpause_icon;
}

.progress_bar_wrapper {
    grid-area: progress_bar;
}

.volume_icon {
    grid-area: volume_icon;
}

.volume_bar_wrapper {
    grid-area: volume_bar;
}

.bar_wrapper {
    position: relative;
}

.bar {
    background-color: rgba(255, 255, 255, 0.125);
    border-radius: 0.25rem;
    position: absolute;
    top: 50%;
    height: 10px;
    left: 0.5rem;
    right: 0.5rem;
    transform: translateY(-50%);
    overflow: hidden;
    cursor: pointer;
}

.progress {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.volume_progress {
    transition: all 0.1s ease;
}

.current_time {
    position: absolute;
    left: 0.5rem;
    bottom: 0;
    color: var(--color-font-tertiary);
    font-weight: bold;
    opacity: 0.5;
    font-size: 0.8rem;
}

.duration {
    position: absolute;
    right: 0.5rem;
    bottom: 0;
    color: var(--color-font-tertiary);
    font-weight: bold;
    opacity: 0.5;
    font-size: 0.8rem;
}

.media iframe {
    position: relative !important;
}
