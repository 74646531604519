.search {
    height: 2rem;
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    color: var(--color-font-main);
    font-weight: bold;
    margin: 0.5rem 0;
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
}

.search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-font-main);
    opacity: 0.3; /* Firefox */
}
