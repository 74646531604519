.media {
    box-sizing: border-box;
    max-height: 70vh;
    max-width: 100%;
    cursor: zoom-in;
}

.media_max {
    max-height: none;
    cursor: zoom-out;
}
