header {
    /* height: var(--header-height);
    line-height: var(--header-height); */
    background: var(--color-main-header);
    box-shadow: var(--shadow-el-2);
    z-index: 10;
}

.main_links {
    height: 1.5rem;
    line-height: 1.5rem;
    /* height: calc(var(--header-height) / 2); */
    /* line-height: calc(var(--header-height) / 2); */
}

.listing {
    vertical-align: top;
    /* color: white; */
    color: var(--color-primary);
    font-weight: bold;
    font-size: 0.85rem;
    margin-left: 1rem;
    cursor: pointer;
    text-decoration: underline;
}
.listing.disabled {
    color: var(--color-font-secondary);
    text-decoration: none;
    cursor: auto;
}

.search_label {
    color: var(--color-font-secondary);
    font-weight: bold;
    margin-left: 1rem;
}

.search {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-font-secondary);
    font-weight: bold;
    border: none;
    outline: none;
    padding-left: 0.2rem;
    border-radius: 0.25rem;
}

.subreddit_current {
    height: 2.5rem;
    line-height: 2.5rem;
    padding-left: 0.9rem;
}

.subreddit_icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
}

.subreddit_title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--color-font-tertiary);
    vertical-align: top;
}

.subreddit_search_error {
    vertical-align: top;
    color: #BB4444;
    font-weight: bold;
    font-size: 0.85rem;
    margin-left: 0.5rem;
}
