.subreddit {
    height: 1rem;
    line-height: 1rem;
    margin-top: 0.25rem;
    white-space: nowrap;
}

.checkbox {
    height: 1rem;
    width: 1rem;
    vertical-align: top;
    margin: 0;
}

.title {
    color: var(--color-primary);
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    vertical-align: top;
    padding-left: 0.5rem;
}

.subscribers {
    color: var(--color-font-tertiary);
    font-size: 0.8rem;
    vertical-align: top;
    font-weight: bold;
    margin-left: 0.5rem;
    float: right;
}




.checkbox {
    position: relative;
    display: inline-block;
    vertical-align: top;
    /* height: var(--checkbox-height); */
    /* width:  var(--checkbox-height); */
    height: 1rem;
    width: 1rem;
    color: white;
    box-sizing: border-box;
    border: 2px solid rgba(255, 255, 255, 0.3);
    font-size: 1rem;
    line-height:  1rem;
    text-align: center;
}

.checkbox:hover {
    background-color: #666;
}

.checkbox:active {
    background-color: #EEE;
    color: #444;
}

.checkbox.checked:before {
    content: "";
    height: 0.5rem;
    width: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
}
