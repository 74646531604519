.wrapper {
    /* padding-left: 0.5rem; */
}

/* Replies to a comment (recursive) */
.comments {
    padding-left: 1.5rem;
}

.comment_even {
    background-color: var(--color-02dp);
}

.comment_odd {
    background-color: var(--color-03dp);
}

.comment {
    padding: 0.25rem 0 0.5rem 0.5rem;
}

.author_info {
    color: var(--color-font-tertiary);
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 0.2rem;
    vertical-align: top;
}
.author_is_op {
    /* color: var(--color-primary); */
    border-bottom: 2px solid var(--color-primary);
    font-size: 0.8rem;
}

.score {
    margin-left: 0.5rem;
    color: #999;
    font-weight: normal;
}

.body {
    color: var(--color-font-main);
    font-size: 0.8rem;
    padding: 0 0.25rem 0.5rem 0;
}

.body a {
    font-weight: bold;
    color: var(--color-primary);
}

.body blockquote {
    border-left: 0.2rem solid var(--color-font-tertiary);
    margin: 0.5rem 0 0 1rem;
    padding-left: 01rem;
}

.body p {
    margin: 0.5rem 0 0 0;
}

.body h1 {
    margin: 0.5rem 0 0 0;
    font-size: 1.2rem;
}


.award {
    margin-left: 0.5rem;
    vertical-align: top;
    display: inline-block;
    height: 0.8rem;
    min-width: 0.8rem;
    border-radius: 50%;
    line-height: 0.8rem;
    text-align: center;
    font-size: 0.6rem;
}

.gold {
    background-color: gold;
}

.silver {
    background-color: silver;
}
